import React from "react";
import { routes } from "./routes";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";
import { useAuth } from "../contexts/AuthContext";
import { useWidget } from "../hooks/useWidget";
import { Layout } from "../components/Layout";

const CustomRoutes = () => {
  const auth = useAuth();

  React.useEffect(() => {
    auth.dictionary();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    async function VerifyAuth() {
      return Promise.resolve(await auth.Authenticate());
    }
    if (!auth.user) {
      VerifyAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let url = window.location.href;
  // if (!url.includes("academy.tuclase.cl")) {
  //   return useWidget(auth);
  // }

  useWidget(auth);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((route, idx) => {
            const Element = route.requireAuth ? (
              <ProtectedRoute>
                {" "}
                <route.element />{" "}
              </ProtectedRoute>
            ) : (
              <route.element />
            );

            return <Route key={idx} path={route.path} element={Element} />;
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default CustomRoutes;
