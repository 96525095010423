import axios from "axios";
import { iappUrl } from "../apirest";

export async function getUserNru(args) {
  try {
    let { courseid, userid } = args;
    let { data } = await axios.get(`${iappUrl}/${courseid}/${userid}`);
    // console.log({ getUserNru: data });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}
