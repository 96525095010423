import { setActivityProgressCompletedManually } from "../services/moodle/resources";
import {
  getCertificateAndFeedbackFromCourse,
  normalizeCategories,
  normalizer,
} from "../services/tipddy-v2";
import { getMoodleCategories } from "../services/moodle/categories";
import { createContext, useContext, useState } from "react";
import { requestAutoLogin } from "../services/moodle/userauth";
import { useAuth } from "./AuthContext";
import {
  getCoreCourseContent,
  getCourseProgress,
  getCourseUsersInfo,
  getGradeReportbyCourse,
  getOverviewGrades,
  getUserCourses,
  getUsers,
} from "../services/moodle/courses";
import {
  MOODLE_STRINGS,
  TIPDDY_MODULES,
  TIPDDY_URLS,
} from "../services/tipddy-v2/state";

const MoodleContextV2 = createContext(null);

export const MoodleProviderV2 = ({ children }) => {
  /**
   * Extracting user for cleaner code
   */
  const { user } = useAuth();
  const config = {
    baseURL: "https://par.tuclase.cl",
  };

  /**
   * Global States for Moodle Context
   */
  const [categories, setCategories] = useState(null);
  const [selectedView, setSelectedView] = useState(0);
  const [onlineModules, setOnlineModules] = useState(null);
  const [programModules, setProgramModules] = useState(null);
  const [courses, setCourses] = useState(null);
  const [grades, setGrades] = useState(null);
  const [pageEmbed, setPageEmbed] = useState(null);
  const [currentModules, setCurrentModules] = useState(null);
  const [currentModuleId, setCurrentModuleId] = useState(null);
  const [currentResourceId, setCurrentResourceId] = useState(null);
  const [nextModuleId, setNextModuleId] = useState(null);
  const [nextResourceName, setNextResourceName] = useState(null);
  const [prevModuleId, setPrevModuleId] = useState(null);
  const [nextResourceId, setNextResourceId] = useState(null);
  const [prevResourceId, setPrevResourceId] = useState(null);
  const [, /* isLastModule */ setLastModule] = useState(null);
  const [, /* isFirstModule */ setFirstModule] = useState(null);
  const [isLastResource, setLastResource] = useState(null);
  const [isFirstResource, setFirstResource] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [diagnostic, setDiagnostic] = useState(null);
  const [humhub, setHumhub] = useState(false);
  const [humhubURL, setHumhubURL] = useState(false);

  /**
   * Inital handler for different State CourseContent Getter is not Resolved at this stage
   * @param { Array } modules
   */
  // function setInitialCurrentModules(modules, course_id) {
  //   /**
  //    * First load first Content and first module
  //    */
  //   setCurrentModuleId(modules[0].id);
  //   /**
  //    * Start de embed request with the first content
  //    */
  //   setInitalEmbed(modules, course_id).catch(console.log);
  //   /**
  //    * Start the navigation finder with the index of the content, at this moment the function for the modules getter
  //    * is not resolve so we pass the modules as well
  //    */
  //   setNavigationModules(modules[0], modules);
  // }
  /**
   */
  async function setLastAcces({ resource_data, modules, course_id }) {
    for (let MODULE of modules) {
      let trackingResource = MODULE.modules.filter((d) => d.completiondata);
      let hasProgress = await actions.getCurrentCourseProgressPercentage({
        course_id,
      });
      /* use to deploy summary as first content */
      if (hasProgress === 0 || !hasProgress) {
        let res = MODULE.modules[0];
        return actions.requestPageEmbed({
          pageurl: res.url,
          module: MODULE,
          resource: res,
          modules,
          course_id,
          last_access: true,
        });
      }
      if (trackingResource.length > 0 && hasProgress > 0) {
        for (const res of trackingResource) {
          if (res.completiondata.state === 0) {
            console.log({ res });
            return actions.requestPageEmbed({
              pageurl: res.url,
              module: MODULE,
              resource: res,
              modules,
              course_id,
              last_access: true,
            });
          }
        }
      }
    }

    /*     let moduleIndex = modules.findIndex(
      (m) => m.id === resource_data.module_id
    );

    let res = modules[moduleIndex].modules.find(
      (res) => res.id === resource_data.cmid
    ); */

    /*     actions.requestPageEmbed({
      pageurl: res.url,
      module: modules[moduleIndex],
      resource: res,
      modules,
      course_id: course_id,
    }); */
  }

  /**
   *
   * Handler for the navigation Buttons Service finding the next and prev Module handling the last and first exception
   * @param { Object } module
   * @param { Array } modules
   * @returns
   */
  function setNavigationModules(module, modules = null) {
    /**
     *
     * Checking if this is using at inital stage or during the different stages of the use of the app
     * where CurrentModules already exists
     */
    let MODULES = modules ? modules : currentModules;
    /**
     * Current Module Index
     */
    let currentIdx = MODULES.findIndex((m) => m.id === module.id);

    /**
     * Handling the last module exception
     */
    if (currentIdx === MODULES.length - 1) {
      setPrevModuleId(currentIdx - 1);
      setNextModuleId(null);
      setLastModule(true);
      return;
    }

    /**
     * Handling the first module exception
     */
    if (currentIdx === 0) {
      setNextModuleId(currentIdx + 1);
      setPrevModuleId(null);
      setFirstModule(true);
      return;
    }

    /**
     * Any module treatment
     */
    setNextModuleId(currentIdx + 1);
    setPrevModuleId(currentIdx - 1);
  }

  /*
   * Handler for the navigation Buttons Service finding the next and prev Resource handling the last and first exception
   * @param { Object } module
   * @param { Array } modules
   * @returns
   */
  function setNavigationResources({ resource, modules = null, module = null }) {
    let isFinishedCourse = false;
    /**
     * Validation for modules
     */
    let MODULES = modules ? modules : currentModules;

    /**
     * Validation for Current Module
     */
    let currentModule = module
      ? module
      : currentModuleId
      ? MODULES.find((m) => m.id === currentModuleId)
      : MODULES[0];

    /**
     * Initial Next module Index
     */
    let nextModuleIndex = MODULES.findIndex((m) => m.id === currentModule.id);

    /**
     * Initialize variables
     */
    let nextModuleName;
    let nextResourceName;

    /**
     * If there is a initial next module index, setting the next module name
     */
    if (nextModuleIndex !== MODULES.length - 1) {
      nextModuleName = MODULES[nextModuleIndex + 1].name;
    }

    /**
     * Current Modules
     */
    let INNER_MODULES = currentModule.modules;

    /**
     * Current Resource index
     */
    let resourceIdx = INNER_MODULES.findIndex((r) => r.id === resource.id);
    let moduleIdx = MODULES.findIndex((m) => m.id === module.id);

    if (moduleIdx === MODULES.length - 1) {
      isFinishedCourse = true;
    }

    /**
     * If current module only have 1 resource
     */
    if (INNER_MODULES.length === 1) {
      setLastResource(true);
      setFirstResource(true);
      setPrevResourceId(null);
      setNextResourceId(null);
      isFinishedCourse
        ? setNextResourceName("Fin del curso")
        : setNextResourceName(`Siguiente Unidad: ${nextModuleName}`);

      return;
    }

    /**
     * Handling last resource
     */
    if (resourceIdx === INNER_MODULES.length - 1) {
      setPrevResourceId(resourceIdx - 1);
      setNextResourceId(null);
      setLastResource(true);
      setFirstResource(false);

      isFinishedCourse
        ? setNextResourceName("Fin del curso")
        : setNextResourceName(`Siguiente Unidad: ${nextModuleName}`);
      return;
    }

    /**
     * Handling first resource
     */
    if (resourceIdx === 0) {
      nextResourceName = currentModule.modules[resourceIdx + 1].name;
      setNextResourceId(resourceIdx + 1);
      setPrevResourceId(null);
      setFirstResource(true);
      setLastResource(false);
      setNextResourceName(nextResourceName);
      return;
    }

    /**
     * Updating resource name after logic
     */
    nextResourceName = currentModule.modules[resourceIdx + 1].name;

    /**
     * Result for the navigation states
     */ /* setInitalEmbed; */
    setNextResourceName(nextResourceName);
    setFirstResource(false);
    setLastResource(false);
    setNextResourceId(resourceIdx + 1);
    setPrevResourceId(resourceIdx - 1);
  }

  /**
   *
   * @param { Array } modules
   * @returns
   */
  // async function setInitalEmbed(modules, course_id) {
  //   /**
  //    * Loop into the modules to find the first valid module
  //    */
  //   let firstResource;
  //   for (const module of modules) {
  //     /**
  //      * At first encounter for module with modules
  //      */
  //     if (module.modules.length > 0) {
  //       /**
  //        * Request the embed authentication
  //        */
  //       firstResource = module.modules[0];

  //       actions.requestPageEmbed({
  //         pageurl: firstResource.url,
  //         module: module,
  //         resource: firstResource,
  //         modules,
  //         course_id,
  //       });

  //       return;
  //     }
  //   }
  // }

  /**
   * Init the actions Object
   */
  let actions = {};

  /**
   * Handler for the nextModule Navigation
   */
  actions.navigateToNextModule = async function () {
    /**
     * At this point we already have or the logic and touchpoints to set the next module
     */
    let module = currentModules[nextModuleId];
    let resource = module.modules[0];
    let pageurl = resource.url;
    await actions.requestPageEmbed({
      pageurl,
      module,
      resource,
      course_id: currentCourseId,
    });
    /**
     * Reset the navigation touchpoints
     */
    /*     setNavigationModules(module);
     */
  };

  actions.navigateToNextResource = async function () {
    let module = currentModules.find((m) => m.id === currentModuleId);
    let resource = module.modules[nextResourceId];
    let pageurl = resource.url;
    await actions.requestPageEmbed({
      pageurl,
      module,
      resource,
      course_id: currentCourseId,
    });

    /*     setNavigationResources({ resource });
     */
  };

  /**
   * Handler for the prevModule Navigation
   */
  actions.navigateToPrevModule = async function () {
    /**
     * At this point we have all the logic and touch points to set the prev Module
     */
    let module = currentModules[prevModuleId];
    let resource = module.modules[module.modules.length - 1];
    let pageurl = resource.url;
    await actions.requestPageEmbed({
      pageurl,
      module,
      resource,
      course_id: currentCourseId,
    });
    /**
     * Reset the navigation touchpoints
     */
    /*     setNavigationModules(module);
     */
  };

  actions.navigateToPrevResource = async function () {
    let module = currentModules.find((m) => m.id === currentModuleId);
    let resource = module.modules[prevResourceId];
    let pageurl = resource.url;
    await actions.requestPageEmbed({
      pageurl,
      module,
      resource,
      course_id: currentCourseId,
    });

    /*     setNavigationResources({ resource });
     */
  };

  actions.navigateNext = async function () {
    if (isLastResource) {
      setLastResource(false);
      return await actions.navigateToNextModule();
    }

    return await actions.navigateToNextResource();
  };

  actions.navigatePrev = async function () {
    if (isFirstResource) {
      setFirstResource(false);

      return await actions.navigateToPrevModule();
    }
    return await actions.navigateToPrevResource();
  };

  /**
   * Handler for the userCourses Service
   * @returns
   */
  actions.getCourses = async () => {
    let data = await getUserCourses({
      token: user.token,
      userid: user.id,
      baseUrl: config.baseURL,
    });
    setCourses(data);
    return data;
  };

  /**
   * Get categories and normalize matching courses
   * @returns
   */
  actions.getCategories = async () => {
    /**
     * Get courses
     */
    let courses = await getUserCourses({
      username: user.token,
      userid: user.id,
      baseUrl: config.baseURL,
    });
    /**
     * Get categories
     */
    let tempcategories = await getMoodleCategories({
      token: user.token,
      baseUrl: config.baseURL,
    });

    /**
     * Final Categories with normalize service
     */
    let categories = normalizeCategories({
      courses,
      categories: tempcategories,
    });

    /**
     * Set the state as standard in the context not in the component
     */
    setCategories(categories);
    return categories;
  };

  /**
   *
   * Handler for each course detail navigation
   * @param { Number } course_id
   * @returns
   */
  actions.getCourseContentByID = async (course_id, firstload = true) => {
    let currentCourse;
    setCurrentCourseId(course_id);

    if (!courses) {
      let tempcourses = await actions.getCourses();
      currentCourse = tempcourses.filter((c) => c.id === Number(course_id))[0];
      setCurrentCourse(currentCourse);
    }

    if (courses) {
      currentCourse = courses.filter((c) => c.id === Number(course_id))[0];
      setCurrentCourse(currentCourse);
    }

    /**
     * Prepare the arguments for the service
     */
    let args = {
      token: user.token,
      id: user.id,
      course_id,
      baseUrl: config.baseURL,
    };

    /**
     * Retrieve the data from the service
     */
    let data = await getCoreCourseContent(args);
    /**
     * Normalize the module with our Custom Normalizer
     */
    let { modules, online, programs, diagnostic } = normalizer(data);
    /**
     * Handler for the inital States
     */

    /*
     * Se busca si tiene el recurso, con link de humhub para desplegar boton en frontend
     * luego se setea el url para tras ser clickeado en la interfaz redireccione
     */
    if (modules) {
      setHumhub(false);
      modules.forEach((element) => {
        element.modules.forEach((e) => {
          if (e.name.toLowerCase().includes("humhub")) {
            setHumhub(true);
            console.log({ e });
            let username = localStorage.getItem("username");
            setHumhubURL(
              `https://${e.humhub_url}/user/auth/login?username=${username}&ws=${e.humhub_ws}`
            );
          }
        });
      });
    }

    if (firstload) {
      setLastAcces({ modules, course_id });

      /*       let isLastResourceFound = JSON.parse(
        localStorage.getItem(`last_acces_cmid-${course_id}-${user.id}`)
      );

      if (isLastResourceFound) {
        setLastAcces({
          resource_data: isLastResourceFound,
          modules: modules,
          course_id: course_id,
        });
      } else {
        setInitialCurrentModules(modules, course_id);
      } */
    }

    /**
     * Pass the current module for later
     */
    setDiagnostic(diagnostic);
    setOnlineModules(online);
    setCurrentModules(modules);
    setProgramModules(programs);
    return { modules, currentCourse };
  };

  /**
   *
   * function to harvest course progress
   * use to know if deploy course summary or content
   * @param { String } course_id
   */

  actions.getCurrentCourseProgressPercentage = async function ({ course_id }) {
    let all_courses = await actions.getCourses();
    let current_course_data = all_courses.filter(
      (c) => c.id === Number(course_id)
    );
    return current_course_data[0].progress;
  };

  /**
   *
   * Handler for the request Embed service
   * This handler receive all the content for the current module and resource
   * @param { String } pageurl
   * @param { Object } module
   * @param { Object } resource
   */
  actions.requestPageEmbed = async function ({
    pageurl,
    module,
    resource = null,
    modules = null,
    course_id = null,
    last_access = null,
  }) {
    try {
      if (!resource && pageurl) {
        setPageEmbed(pageurl);
        return;
      }
      let url;
      /**
       * Request authentication
       */
      if (
        resource.tipddyname !== TIPDDY_MODULES.vimeo &&
        resource.tipddyname !== TIPDDY_MODULES.pdf &&
        resource.tipddyname !== TIPDDY_MODULES.summary
      ) {
        let key = await requestAutoLogin({
          username: user.username,
          baseUrl: config.baseURL,
          tipddytoken: config.token,
        });

        url = `${config.baseURL}/auth/userkey/login.php?key=${key}&wantsurl=${pageurl}`;
      }
      /**
       * Set the current States
       */
      setCurrentModuleId(module.id);
      setCurrentResourceId(resource.id);
      setNavigationModules(module, modules);
      setNavigationResources({ resource, module, modules });

      if (
        resource.completion !== 0 &&
        resource.completiondata.state === 0 &&
        currentCourseId &&
        !last_access
      ) {
        await actions.setResourceCompletedByVisibility(resource);
        setCurrentModules(null);
        await actions.getCourseContentByID(currentCourseId, false);
      }
      /**
       * Set the state for the CoreVisualComponent
       */

      if (resource.tipddyname === TIPDDY_MODULES.summary) {
        url = resource.url;
      }

      if (resource.tipddyname === TIPDDY_MODULES.vimeo) {
        url = `${TIPDDY_URLS.TIP_PLAYER}&video_id=${resource.video_id}&h=${resource.video_h}`;
      }
      if (resource.tipddyname === TIPDDY_MODULES.pdf) {
        url = `${resource.tipddyurl}?token=${user.token}`;
      }

      if (resource.tipddytype === MOODLE_STRINGS.ONLINE && resource.embed) {
        actions.onlineSelected();
      }

      if (course_id && module.modules.length > 0 && module.id !== resource.id) {
        localStorage.setItem(
          `last_acces_cmid-${course_id}-${user.id}`,
          JSON.stringify({
            cmid: resource.id,
            user_id: user.id,
            module_id: module.id,
            course_id: course_id,
          })
        );
      }

      setPageEmbed(url);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  actions.getImgUrl = (item) =>
    `${item.overviewfiles[0].fileurl}?token=${user.token}`;

  actions.getScheduleByCategory = async (category_id) => {
    let categorySelected = categories.filter((c) => c.id === category_id)[0];
    let schedules = [];
    for (let course of categorySelected.courses) {
      let data = await getCoreCourseContent({
        token: user.token,
        course_id: course.id,
        baseUrl: config.baseURL,
      });
      let { schedule } = normalizer(data, course.id);
      schedules = [...schedules, schedule];
    }
    return schedules;
  };

  actions.setResourceCompletedByVisibility = async (resource) => {
    const args = {
      token: user.token,
      completed: 1,
      cmid: resource.id,
      baseURL: config.baseURL,
    };
    await setActivityProgressCompletedManually(args);
    return Promise.resolve(await setActivityProgressCompletedManually(args));
  };

  actions.getGrades = async function () {
    let res = await getOverviewGrades(user.token);
    setGrades(res.grades);
    return Promise.resolve(await getOverviewGrades(user.token));
  };

  actions.getProgress = async function () {
    return Promise.resolve(await getOverviewGrades(user.token, config.baseURL));
  };

  actions.getUsers = async function () {
    return Promise.resolve(await getUsers(user.token, config.baseURL));
  };

  actions.contentSelected = async function () {
    setCurrentModules(null);
    setSelectedView(0);
    let { modules } = await actions.getCourseContentByID(currentCourseId);
    setCurrentModuleId(modules[0].id);
  };
  actions.programSelected = async function () {
    setCurrentModuleId(programModules[0].id);
    setCurrentModules(programModules);
    setSelectedView(1);
  };
  actions.humhubSelected = async function () {
    if (humhubURL) {
      window.open(`${humhubURL}`, "_blank");
    }
  };
  actions.onlineSelected = async function () {
    setCurrentModuleId(onlineModules[0].id);
    setCurrentModules(onlineModules);
    setSelectedView(2);
  };

  actions.getProgressDetail = async function ({ course }) {
    let args = {
      token: user.token,
      id: user.id,
      course_id: course.id,
      baseUrl: config.baseURL,
    };
    /**
     * Retrieve the data from the service
     */
    let data = await getCoreCourseContent(args);

    /**
     * Normalize the module with our Custom Normalizer
     */
    let { modules } = normalizer(data);

    let { cert, feed } = getCertificateAndFeedbackFromCourse({ modules });

    return {
      cert,
      feed,
    };
  };

  actions.getProgressDetail = async function (course_id) {
    const args = {
      token: user.token,
      id: user.id,
      course_id: course_id,
      baseURL: config.baseURL,
    };
    return Promise.all([
      await getGradeReportbyCourse(args),
      await getCourseProgress(args),
    ]);
  };
  /*
   * Gets data if a user have a group in the current course
   */

  actions.getCourseUserList = async function (id) {
    let args = {
      token: user.token,
      course_id: id,
    };

    let data = await getCourseUsersInfo(args);
    // console.log("getCourseUserInfo:", data);
    if (data.groups.length === 0) {
      return null;
    }
    // let user_data = data.filter((e) => e.id === user.id);
    // console.log("core_enrol_get_enrolled_users:", user_data);

    return data.groups[0].name;
  };

  actions.getBanner = async function (id) {
    try {
      let banner = null;
      let tempBannerString = null;
      let content = await getCoreCourseContent({
        course_id: id,
        token: user.token,
      });
      if (content) {
        // console.log(content)
        let modules = content[0].modules;
        for (const module of modules) {
          let isBanner =
            module.description && module.description.includes("portada-");
          tempBannerString = isBanner && module.description;
          if (tempBannerString) {
            let reg = /<img[^>]+src="(https:\/\/[^">]+)"/g;
            let src = reg.exec(tempBannerString)[1];
            banner = src && src;
          }
        }
      }
      return banner;
    } catch (err) {
      throw new Error(err);
    }
  };

  /**
   *
   * Exports values with states and handlers
   */
  let values = {
    grades,
    courses,
    categories,
    pageEmbed,
    currentModules,
    currentModuleId,
    currentResourceId,
    currentCourseId,
    nextResourceName,
    selectedView,
    currentCourse,
    diagnostic,
    humhub,
    setCurrentModuleId,
    setPageEmbed,
    setCategories,
    setGrades,
    setCourses,
    navigateNext: actions.navigateNext,
    navigatePrev: actions.navigatePrev,
    navigateToPrevResource: actions.navigateToPrevResource,
    navigateToNextResource: actions.navigateToNextResource,
    navigateToPrevModule: actions.navigateToPrevModule,
    navigateToNextModule: actions.navigateToNextModule,
    getCategories: actions.getCategories,
    getCourses: actions.getCourses,
    getCourseContentByID: actions.getCourseContentByID,
    requestPageEmbed: actions.requestPageEmbed,
    getGrades: actions.getGrades,
    getProgress: actions.getProgress,
    getCourseImg: actions.getImgUrl,
    contentSelected: actions.contentSelected,
    programSelected: actions.programSelected,
    humhubSelected: actions.humhubSelected,
    onlineSelected: actions.onlineSelected,
    getProgressDetail: actions.getProgressDetail,
    getCourseUserList: actions.getCourseUserList,
    getBanner: actions.getBanner,
  };

  return (
    <MoodleContextV2.Provider value={values}>
      {children}
    </MoodleContextV2.Provider>
  );
};

export const useMoodleV2 = () => useContext(MoodleContextV2);
