import axios from "axios";
import { tipddytoken, moodleURL } from "../apirest";

const baseURL = "https://par.tuclase.cl/";

export const getCurrentUser = async ({ token, username }) => {
  const { data } = await axios.get(
    `${moodleURL}${token}&wsfunction=core_user_get_users_by_field&field=username&values[0]=${username}&moodlewsrestformat=json`
  );
  if (data.errorcode) {
    return data;
  }
  const user = data[0];
  return { ...user, token };
};

/* auth_userkey_request_login_url */

const login = async ({ username, password }) => {
  const respAuth = await axios.get(
    `${baseURL}login/token.php?username=${username}&password=${password}&service=moodle_mobile_app`
  );
  const data = respAuth.data;
  if (data.error) {
    throw new Error(data.error);
  }
  const { token } = data;
  return { data, token };
};

export const requestAutoLogin = async ({ username }) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${tipddytoken}&moodlewsrestformat=json&wsfunction=auth_userkey_request_login_url&user[username]=${username}`
    );
    // console.log({ requestAutoLogin: data, username, tipddytoken });
    let key = data.loginurl.split("=")[1];
    return key;
  } catch (e) {
    throw new Error(e);
  }
};

/* export const requestAutoLogout = () => {
  let url = `${baseURL}auth/userkey/logout.php?return=${API.dev}`

} */

export const loginMoodle = async ({ username, password }) => {
  try {
    const { token } = await login({ username, password });
    return token;
  } catch (e) {
    throw new Error(e);
  }
};

export const resetPasword = async (args) => {
  console.log({ args });
  try {
    const { data } = await axios.post(
      "https://par.tuclase.cl/tipddy/admin/iapp/services/restore-password.php/user/changepasswordforce",
      { ...args },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const authMiddleware = async ({ token, username }) => {
  try {
    const user = await getCurrentUser({ token, username });
    if (user.errorcode) {
      localStorage.setItem("username", username);
      localStorage.setItem("token", token);
      return user;
    }
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    return { ...user, token };
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};
