/* 
RutOtec=76299492-5
Token=42AC9AD5-F09B-45B8-A350-23EE9B82DA3C
CodSence=1238004415
CodigoCurso=6200761
LineaCapacitacion=3
RunAlumno=18768227-4
IdSesionAlumno={{Id-sesion-en-LMS}}
UrlRetoma=http://xxxx/exito
UrlError=http://xxxx/error 
*/

export const SENCE_DEV = {
  RUT_OTEC: "76299492-5",
  COD_SENCE: "1238004415",
  CODIGO_CURSO: "6200761",
  LINEA_CAPACITACION: "3",
  TOKEN: "42AC9AD5-F09B-45B8-A350-23EE9B82DA3C",
  URL_LOGIN: "https://sistemas.sence.cl/rce/Registro/IniciarSesion",
  URL_LOGOUT: "https://sistemas.sence.cl/rce/Registro/CerrarSesion",
  URL_RETURN_LOGIN:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-login-dev.php",
  URL_RETURN_LOGOUT:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-logout-dev.php",
  URL_LOGIN_ERROR:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-login-error-dev.php",
  URL_LOGOUT_ERROR:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-logout-error-dev.php",
};

export const SENCE_PROD = {
  RUT_OTEC: "76299492-5",
  COD_SENCE: "1238004415",
  CODIGO_CURSO: "6205890",
  LINEA_CAPACITACION: "3",
  TOKEN: "42AC9AD5-F09B-45B8-A350-23EE9B82DA3C",
  URL_LOGIN: "https://sistemas.sence.cl/rce/Registro/IniciarSesion",
  URL_LOGOUT: "https://sistemas.sence.cl/rce/Registro/CerrarSesion",
  URL_RETURN_LOGIN:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-login.php",
  URL_RETURN_LOGOUT:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-logout.php",
  URL_LOGIN_ERROR:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-login-error.php",
  URL_LOGOUT_ERROR:
    "https://dev2.tipddy.cl/tipddy-web/sence-tipddy/sence-logout-error.php",
};

export const LOGIN_RESULT = {
  CodSence: "",
  CodigoCurso: "",
  IdSesionAlumno: "",
  IdSesionSence: "",
  RunAlumno: "",
  FechaHora: "",
  ZonaHoraria: "",
  LineaCapacitacion: "",
};
