import React from "react";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { HeaderProgress } from "./Header";
import { ProgressGroup } from "./ProgressGroup";
import { ProgressTitle } from "./ProgressTitle";
import { ProgressTable } from "./ProgressTable";
import { useMoodle } from "../../contexts/MoodleContext";
import { ProgressChartItem } from "./ProgressChart";
import { ProgressRowItem } from "./ProgressRowItem";
import { useAuth } from "../../contexts/AuthContext";
import { requestAutoLogin } from "../../services/moodle/userauth";

const Progress = (/* {data} */) => {
  const [grades, setGrades] = React.useState(null);
  const [tableTitles, setTableTitles] = React.useState(null);
  const moodle = useMoodle();
  const { lang } = useAuth();
  const [sessionKey, setSessionKey] = React.useState(null);
  const { user } = useAuth();

  React.useEffect(() => {
    lang && console.log(lang["History"]);
    if (lang && !tableTitles) {
      setTableTitles([
        lang["History"].first_column,
        lang["History"].second_column,
        lang["History"].third_column,
        lang["History"].fourth_column,
        lang["History"].fifth_column,
        lang["History"].sixth_column,
      ]);
    }
    if (!sessionKey) {
      requestAutoLogin({ username: user.username }).then((res) =>
        setSessionKey(res)
      );
    }
  }, [lang, sessionKey]);

  React.useEffect(() => {
    !moodle.courses && moodle.getCourses();
    fetchGrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchGrades() {
    const res = await moodle.getProgress();
    /*     console.log({ res }); */
    setGrades(res.grades);
  }

  return (
    <>
      {lang && (
        <>
          <Navbar />
          {sessionKey && (
            <iframe
              src={`https://par.tuclase.cl/auth/userkey/login.php?key=${sessionKey}&wantsurl=https://par.tuclase.cl/`}
              id="login-token"
              frameBorder="0"
              width="0"
              allow="autoplay ; microphone; camera"
              height={"0"}
              title="login-token"
            />
          )}
          <div style={styles.progressBlock}>
            <div style={styles.wrapper}>
              <ProgressTitle title={lang["History"].first_title} />
              <ProgressTable headers={tableTitles}>
                {moodle.courses &&
                  grades &&
                  moodle.courses.map((c, idx) => {
                    let grade = grades.filter((g) => g.courseid === c.id)[0]
                      .grade;
                    return (
                      <ProgressRowItem
                        key={idx}
                        course={c}
                        grade={grade}
                        lang={lang}
                      />
                    );
                  })}
              </ProgressTable>
            </div>
            <HeaderProgress lang={lang} />
            <ProgressGroup>
              {moodle.courses &&
                moodle.courses.map((c, idx) => {
                  return <ProgressChartItem key={idx} course={c} lang={lang} />;
                })}
            </ProgressGroup>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

const styles = {
  wrapper: {
    paddingTop: "12rem",
  },
  progressBlock: {
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
    paddingBottom: "5rem",
  },
};

export default Progress;
