import React from "react";
import { useNavigate } from "react-router-dom";

export const ProgressRowItem = ({ course, grade, lang }) => {
  const navigation = useNavigate();
  const [data /* setData */] = React.useState({
    name: course.displayname,
    start: new Date(course.startdate * 1000).toLocaleDateString(),
    end:
      course.enddate !== 0
        ? new Date(course.enddate * 1000).toLocaleDateString()
        : "Sin fecha de termino",
  });

  const handleDetailNavigation = () =>
    navigation(`/listcourses/progress/${course.id}`, {
      state: { name: data.name },
    });

  return (
    <>
      <tr>
        <th onClick={handleDetailNavigation} scope="row" className="title-row">
          {data.name}
        </th>
        <td>{grade}</td>
        <td>{data.start}</td>
        <td>
          {data.end === "Sin fecha de termino"
            ? lang["History"].fourth_column_answer
            : data.end}
        </td>
        <td>{lang["History"].fifth_column_answer}</td>
        <td>{lang["History"].sixth_column_answer}</td>
      </tr>
    </>
  );
};
