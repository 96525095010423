import { query } from "../../utils";
import { /* SENCE_DEV,  */ SENCE_PROD } from "./state";

export const SenceValidation = (args) => {
  let { idSence, auth, session = null, isLogin, nru } = args;

  let dv = auth.user.idnumber[auth.user.idnumber.length - 1] || null;
  let rut = auth.user.idnumber.slice(0, -1) || null;
  let rut_value = null;
  if (rut) {
    rut_value = `${rut}-${dv}`;
  }

  let form = query("#form-sence");
  let CodSence = query("#CodSence");
  let idSesionAlumno = query("#IdSesionAlumno");
  let runAlumno = query("#RunAlumno");
  let codCurso = query("#CodigoCurso");

  CodSence.value = idSence;
  idSesionAlumno.value = auth.user.token;
  runAlumno.value = rut_value;
  codCurso.value = nru;

  if (!isLogin) {
    let idSesionSence = query("#IdSesionSence");
    idSesionSence.value = session.IdSesionSence;
    /*     query("#form-sence").action = SENCE_DEV.URL_LOGOUT;
    query("#UrlRetoma").value = SENCE_DEV.URL_RETURN_LOGOUT;
    query("#UrlError").value = SENCE_DEV.URL_LOGOUT_ERROR; */
    query("#form-sence").action = SENCE_PROD.URL_LOGOUT;
    query("#UrlRetoma").value = SENCE_PROD.URL_RETURN_LOGOUT;
    query("#UrlError").value = SENCE_PROD.URL_LOGOUT_ERROR;
  }

  console.log({
    rut_value,
    form,
    CodSence: CodSence.value,
    idSesionAlumno: idSesionAlumno.value,
    runAlumno: runAlumno.value,
    codCurso: codCurso.value,
  });

  form.submit();
};
/* export const SenceValidation = (idSence, auth, sence = null, isLogin) => {
  const dv = auth.user.idnumber[auth.user.idnumber.length - 1];
  const rut = auth.user.idnumber.slice(0, -1);
  const rut_value = `${rut}-${dv}`;
  const form = document.querySelector("#form-sence");
  const CodSence = document.querySelector("#CodSence");
  const idSesionAlumno = document.querySelector("#IdSesionAlumno");
  const runAlumno = document.querySelector("#RunAlumno");

  CodSence.value = idSence;
  idSesionAlumno.value = auth.user.token;
  runAlumno.value = rut_value;

  if (!isLogin) {
    let idSesionSence = document.querySelector("#IdSesionSence");
    idSesionSence.value = sence.IdSesionSence;
    document.querySelector("#form-sence").action = SENCE_PROD.URL_LOGOUT;
    document.querySelector("#UrlRetoma").value = SENCE_PROD.URL_RETURN_LOGOUT;
    document.querySelector("#UrlError").value = SENCE_PROD.URL_LOGOUT_ERROR;
  }

  form.submit();
}; */
